@font-face {
  font-family: 'Lexend-Light';
  src: url('../fonts/Lexend-Light.woff') format('woff'), url('../fonts/Lexend-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend-Regular';
  src: url('../fonts/Lexend-Regular.woff') format('woff'), url('../fonts/Lexend-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend-ExtraLight';
  src: url('../fonts/Lexend-ExtraLight.woff') format('woff'), url('../fonts/Lexend-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}


html,
body,
* {
  font-family: 'Lexend', sans-serif 'Roboto', sans-serif;
}